import * as React from "react";
import { withPreviewResolver } from "gatsby-source-prismic";

const PreviewPage = ({ isPreview, isLoading }) => {
  if (isPreview === false) return "Not a preview!";

  return (
    <>
      <p>Loading</p>
    </>
  );
};

export default withPreviewResolver(PreviewPage, {
  repositoryName: `hourdirectory`,
  linkResolver: ({ node, key, value }) => doc => {
    // Your link resolver
    if (doc.type === "about") {
      return `/about`;
    } else {
      return `/${doc.uid}`;
    }
  },
});
